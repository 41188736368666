<template>
  <v-main class="login">
    <div class="px-3 mt-10">
      <p class="body-2 mb-10">
        メールに記載された<br>6桁の認証コードを入力してください。
      </p>

      <ValidationObserver ref="observer" v-slot="{ invalid }">
        <v-row class="mb-4">
          <v-col cols="12" sm="6" class="pb-0">
            <p class="subtitle-2 font-weight-black ma-0 text-center">認証コード</p>
            <ValidationProvider ref="provider" v-slot="{ errors }" name="認証コード"
              rules="required|max:6|min:6">
              <v-text-field v-model="code" type="number" maxlength="6" single-line outlined dense
              :error-messages="errors" required>
              </v-text-field>
            </ValidationProvider>
          </v-col>
        </v-row>

        <div class="text-center">
          <v-btn rounded :disabled="invalid"
            class="maincolor-bg default_button mb-5" @click.stop="auth()">認証</v-btn>
        </div>

        <div class="text-center" v-if="$route.query.scene === 'create'">
          <v-btn rounded class="caption font-weight-black mb-10"
            style="background-color:#EFEFEF;" to="register_email">
            メールアドレス認証画面に戻る
          </v-btn>

          <p class="caption">
            ※メールが届かなかった方は、<br>再度メールアドレスを設定して下さい
          </p>
        </div>
      </ValidationObserver>

      <div class="text-center caption pt-10">
        <p class="caption" v-if="init.inquiry_mail">
          <v-icon>far fa-envelope</v-icon>
          <a :href="'mailto:'+init.inquiry_mail">お問い合わせ</a>
        </p>
      </div>
    </div>

    <v-overlay :value="loading">
      <v-progress-circular :size="80" :width="2" class="maincolor" indeterminate></v-progress-circular>
    </v-overlay>
  </v-main>
</template>

<script>
import { required, max, min } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
setInteractionMode('eager')
extend('required', {
  ...required,
  message: '{_field_} は必須項目です。',
})

extend('max', {
  ...max,
  message: '6文字で入力してください。',
})

extend('min', {
  ...min,
  message: '6文字で入力してください。',
})

export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      code: null,

      init: this.storageGet('*'),
      loading: false,
      show: false,
    };
  },
  async created () {

  },
  methods: {
    auth: function(){
      this.$refs.observer.validate().then(async result => {
        if (!result) {
          return;
        }
        try {
          //loadingを表示
          this.loading = true
          //認証コードのチェック
          const auth_req = {
            'mail'     : this.init.login_id,
            'auth_code': this.code,
          }
          await this.apiCallCustomerPF('/auth_code/check', auth_req);
          this.storageSave(JSON.stringify({
              'auth_code': this.code,
          }));

          // 新規登録の場合は、ユーザ情報登録へ
          if (this.$route.query.scene === 'create') {
            const callback = encodeURIComponent('alert:{"_vue_param": true, "login_action":"new-account", "yamaya_id":"@yamaya_id"}')
            this.showEcPage('/omni_member_add.html', 'callback=' + callback + '&email=' + encodeURIComponent(this.init.login_id))
            return;
          }

          // ログインの場合

          // トークン発行はトップ画面で行う。
          // // アクセストークン発行
          // const token_req = {
          //   'type'      : 'App',
          //   'auth_code' : this.code,
          //   'os'        : this.init.os,
          //   'user_id'   : this.init.user_id,
          //   'yamaya_id' : this.init.device_uid,
          // };
          // const token_res = await this.apiCallCustomerPF('/access_token', token_req);
          // this.storageSave(JSON.stringify({
          //   'access_token': token_res.access_token,
          // }));

          /* トップ画面に遷移 */
          this.requestToNativeToShowTopPageAfterNewAccountOrLogin('login', this.init.device_uid);
          this.loading = false;
        } catch(e) {
          console.log(e);
          this.loading = false
          this.callDialog('認証コードエラー', '認証コードを確認してください。', 3);
        }
      });
    }
  }
};
</script>

<style scoped>
.fontsize12> >>label {
  font-size: 12px;
}

a {
  color: #333333;
}
</style>
